import React from 'react';
import Navbar from '../../components/navbar';
import Input from '../../components/input';
import Textarea from '../../components/textarea';
import { Layout, Image, Content, Title, Form, ColumnContainer, SubmitButton, SmallText} from './styles';
import { useForm } from "react-hook-form";
import { sendContact } from '../../services/api';
import { toast } from 'react-toastify';


const Contact = () =>{

  const { register, handleSubmit} = useForm();

  const onSubmit = (data, e) => {
    
    sendContact(data).then( (res) => {
      console.log(res);
      toast.success("Mensagem enviada com sucesso!", {position: toast.POSITION.TOP_CENTER})
    })
    e.target.reset();
  }

  return <div>
      <Navbar/>
      <Layout>
        <Content>
        <Image
          height='75%'
          style={{marginTop: '5%', marginLeft: '2%'}}
          src={require("../../images/conversation.png")}
          alt="óculos e texto"
          />
          <ColumnContainer>
            <Title>Fale Conosco</Title>
              <Form onSubmit={handleSubmit(onSubmit)} >
                    <Input placeholder="Seu nome" name="name" autoFocus="on" ref={register({ required: true })} ></Input>
                    <Input placeholder="E-mail" name="email" type="email" ref={register({ required: true })} ></Input>
                    <Textarea placeholder="Deixe sua mensagem" name="message" ref={register({ required: true })} ></Textarea>
                <SubmitButton type="submit">Enviar</SubmitButton>
            </Form>
            <hr style={{width: '100%', marginTop: '3%', color: '#f6f6f6', marginLeft: '3%'}}/>
            <SmallText>Preceptor Educação a Distância.<br/>
              Rua Cel. Alves Teixeira, 1290, sala 5, bairro Dionísio Torres, CEP 60135-208, Fortaleza-CE</SmallText>
          </ColumnContainer>
        </Content>
      </Layout>
  </div>
}
export default Contact;