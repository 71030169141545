import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from '../../pages/home/index';
import About from '../../pages/sobre-nos/index';
import Prices from '../../pages/planos/index';
import Contact from '../../pages/contato/index'
import Partners from '../../pages/parcerias/index';
import Register from '../../pages/cadastro/index';
import Login from '../../pages/login/index';
import Results from '../../pages/resultados';
import routes from '../../routes/routes';
import theme from '../../styles/theme';
import {InputProvider} from '../../InputContext';
import {ResultProvider} from '../../ResultContext';
import {UserProvider} from '../../UserContext';
import {ThemeProvider} from 'styled-components';
import { ToastContainer } from 'react-toastify';
import './app.css';
import sockethandler from '../../services/websockets';

import {identify} from '../../services/api';


const { home, about, partners, prices, contact, login, register, results } = routes;

function App() {

    const [user, setUser] = useState({});
    const [input, setInput] = useState({});
    const [result, setResult] = useState({});

    // Call once every page refresh
    useEffect(() => {
        // Send request to get a cookie and identify the
        // browser in subsequent requests to the API.
        (async () => {

            let userLogged = false // Trocar depois para uma função que identifica se existe token e checa o token!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            if (!userLogged) {
                // Who am I?
                let response = await identify()
                let machine_id = response['anonymous_user_id']
                console.log("Machine ID: " + machine_id)
                // Connect to websocket
                sockethandler.start()
                sockethandler.publicChannel( 'App.AnonymousUser.' + machine_id ).listen( 'AnonymousRequestProcessed', ( data ) => {
                    console.log(data)
                    setResult({
                            id: data.id,
                            status: data.status,
                            resultado: data.data.texts
                        });
                })
            } else {
                // Who am I?
                let res = await identify();
                console.log( res );
                // Connect to websocket
            }
        })()
    }, [])

    return (
        <UserProvider value = {{user, setUser}}>
            <ResultProvider value ={{result, setResult}}>
                <InputProvider value={{input, setInput}}>
                    <ThemeProvider theme={{...theme}}>
                        <Router>
                            <Switch>
                            <Route exact path={home}>
                                <Home/>
                            </Route>
                            <Route path={about}>
                                <About/>
                            </Route>
                            <Route path={prices}>
                                <Prices/>
                            </Route>
                            <Route path={contact}>
                                <Contact/>
                            </Route>
                            <Route path={partners}>
                                <Partners/>
                            </Route>
                            <Route path={register}>
                                <Register/>
                            </Route>
                            <Route path={login}>
                                <Login/>
                            </Route>
                            <Route path={result}>
                                <Results/>
                            </Route>
                            </Switch>
                        </Router>
                    </ThemeProvider>
                <ToastContainer/>
                </InputProvider>
            </ResultProvider>
        </UserProvider>
        
    );
  }

export default App;
