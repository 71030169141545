import React, {useState, useContext} from "react";
import { Link } from "react-router-dom";
import { Container, Nav, Links, Logo, Burguer} from "./styles";
import { WhiteButton } from '../buttons';
import { logout } from  '../../services/api';
import logo from "../../images/logo-branca.svg";
import { toast } from 'react-toastify';
import UserContext from '../../UserContext';

function Navbar() {

const { user, setUser } = useContext(UserContext);
const [show, setShow] = useState(false);

const onLogout = () => {
  logout().then( res => {
    console.log(res);
    setUser({
      user: null,
      token: null
    });
    localStorage.removeItem("token");
    if(res.status === 200){
      toast.info("Deslogado com sucesso! Volte logo!", {position: toast.POSITION.TOP_CENTER})
    }
  })
}
  
return user.token ? (
    <Container>
      <Nav>
        <Link to="/">
          <Logo src={logo} alt="Logo" />
        </Link>
        <Links show={show}>
          <Link to="/sobre-nos">Sobre nós</Link>
          <Link to="/contato">Contato</Link>
          <Link to="/resultados">Meus resultados</Link>
          <Link to="/login">
              <WhiteButton onClick={onLogout}>Sair</WhiteButton>
          </Link>
        </Links>
        <Burguer onClick={() => setShow((show) => !show)}>
          <div />
          <div />
          <div />
        </Burguer>
      </Nav>
    </Container>
  ) : (
    <Container>
      <Nav>
        <Link to="/">
          <Logo src={logo} alt="Logo" />
        </Link>
        <Links show={show}>
          <Link to="/planos">Planos</Link>
          <Link to="/parcerias">Parcerias</Link>
          <Link to="/sobre-nos">Sobre nós</Link>
          <Link to="/contato">Contato</Link>
          <Link to="/login">
              <WhiteButton>Entrar</WhiteButton>
          </Link>
        </Links>
        <Burguer onClick={() => setShow((show) => !show)}>
          <div />
          <div />
          <div />
        </Burguer>
      </Nav>
    </Container>
  );
}

export default Navbar;
