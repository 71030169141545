import React, {useContext, useEffect, useState} from 'react';
import { Imagem, PageTitle, Text, Container, ColumnContainer, Button} from './styles';
import TextEditor from '../../../../components/textEditor';
import FileUploader from '../../../../components/fileUpload';
import MyDocument from '../../../../components/resultsPDF';
import InputContext from '../../../../InputContext';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { identify, sendData } from '../../../../services/api';
import { PDFDownloadLink} from '@react-pdf/renderer'
import ResultContext from '../../../../ResultContext'


const Apresentacao = () => {
    const { input, setInput } = useContext(InputContext);
    const { result } = useContext(ResultContext);
    
    const [pdfLink, setPdfLink] = useState(null);

    //Função disparada ao clicar no botão de " Checar plágio "
    const submitData = () => {
      // Apresentar toast com info caso um dos inputs esteja vazio
      if (input.text === null || input.text === undefined){
        toast.info("Insira seu texto e não se esqueça de salvá-lo!", {position: toast.POSITION.TOP_CENTER})
      }
      else if (input.file === undefined || input.file === null){
        toast.info("É necessário enviar um arquivo para realizarmos a comparação com seu texto!", { position: toast.POSITION.TOP_CENTER })
      }

      // Caso esteja tudo certo, faz a comunicação com o backend
      else {
        //Gera form data
        let formData = new FormData();

        formData.set('text', input.text);
        formData.append('file', input.file);
        //Identifica o user que está enviando a request
        identify().then( res => {
          console.log(res);
          });

        //Envia dados pro laravel
        sendData(formData).then( res => {
          console.log(res);
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
       });

        //Limpa as variáveis do input
        setInput({
          file: null,
          text: null
        });
      }
    }

    // Check if result changed
    useEffect(() => {
      // Link não precisa existir caso não exista um resultado de processamento
      let link = null;

      // Se o resultado existe ou já foi inicializado
      if ( result && Object.keys(result).length > 0)
      {
        let equal = ""
        // Gera documento com os dados
        let texts = result.resultado;
        texts.forEach(element => {
          if(element.value === 0){
            equal = equal + "\n" + element.text;
          }
        });
        let doc = <MyDocument data={equal}/>;
        // Cria link de download com o documento
        link = <PDFDownloadLink document={doc} fileName="resultado.pdf" style={{marginTop: "10%", fontSize: "15pt"}}>
          {({loading}) => (loading ? 'Seu resultado estará disponível para download em instantes' : 'Download')}
        </PDFDownloadLink>
      }

      // Salva o link
      setPdfLink( link );

    }, [result]);

    return <div>
            <Imagem>
              <PageTitle>Detector de plágio e comparador de textos</PageTitle>
              <Text>Online <br/> Aqui, agora, sempre e em todo lugar</Text>
              <Container>
                <TextEditor/>
                <ColumnContainer>
                  <FileUploader/>
                  <Button disabled={input.file === null && input.text === null} onClick={submitData}> Checar plágio</Button>
                  { pdfLink }
                </ColumnContainer>   
              </Container>
            </Imagem>
    </div>
}

export default Apresentacao;