import React from 'react';
import Navbar from '../../components/navbar';
import { Layout, Image, Content, Title, ColumnContainer, Text} from './styles';

const About = () =>{
  return <div>
       <Navbar/>
      <Layout>
        <Content>
        <Image
          height='75%'
          style={{marginTop: '5%', marginLeft: '2%'}}
          src={require("../../images/logo-azul.svg")}
          alt="óculos e texto"
          />
          <ColumnContainer>
            <Title>Quem Somos</Title>
            <Text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
            Maecenas in mauris quis turpis pellentesque tempor eu at tellus.
            Curabitur commodo a lectus et suscipit.</Text>
            <Text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
            Maecenas in mauris quis turpis pellentesque tempor eu at tellus.
            Curabitur commodo a lectus et suscipit.</Text>
            <Text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
            Maecenas in mauris quis turpis pellentesque tempor eu at tellus.
            Curabitur commodo a lectus et suscipit.</Text>
          </ColumnContainer>
        </Content>
      </Layout>
  </div>
}
export default About;
