import React, { useContext} from 'react';
import Navbar from '../../components/navbar';
import { Layout, Image, Content, Title, Form, ColumnContainer, SubmitButton, Text} from './styles';
import Input from '../../components/input';
import { useForm } from "react-hook-form";
import { login } from '../../services/api';
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { toast } from 'react-toastify';
import UserContext from '../../UserContext';

const Login = () =>{

  const { user, setUser } = useContext(UserContext);
  const { register, handleSubmit, errors} = useForm();

  const onSubmit = (data, e) => {
    //console.log(data);
    login(data).then( (res) => {
      //console.log(res);
      setUser({
        user: res.user,
        token: res.token
      });
      localStorage.setItem("token", res.token);
    })
    .catch( (error) => {
      if(error.response.status === 401){
        toast.info("Email ou senha incorretos, tente novamente.", {position: toast.POSITION.TOP_CENTER})
      }
    })

    e.target.reset();
  }

  
return user.token ? (
    <Redirect
      to={"/"}
    />
  ) : (
  <div>
      <Navbar/>
      <Layout>
        <Content>
          <Image
          height='75%'
          style={{marginTop: '5%', marginLeft: '2%'}}
          src={require("../../images/personal_data_.png")}
          alt="óculos e texto"
          />
          <ColumnContainer>
            <Title>Bem vindo ao <br/> Contexto Antiplágio</Title>
            <Form onSubmit={handleSubmit(onSubmit)}>
                  <Input placeholder="E-mail" name="email" type="email" ref={register({ required: true })}></Input>
                  <Input placeholder="Senha" name="password" type="password" placeholder="Senha" ref={register({ required: true, minLength: 8 })}></Input>
                  <SubmitButton type="submit">Entrar</SubmitButton>
            </Form>
            <Text>Ainda não tem conta?</Text>
            <Link to="/cadastro">Criar conta</Link>
          </ColumnContainer>
        </Content>
      </Layout>
  </div>
  )
}
export default Login;