import styled from "styled-components";

const Button = styled.button`
  padding: 0.2rem;
  border: none;
  border-radius: 4px;
  transition: 0.25s ease-out;
  cursor: pointer;
`;

export const WhiteButton = styled(Button)`
background-color: white;
color: ${ (props) => props.theme.primary};
height: 50px;
width: 70px;
font-size: 16px;

  :focus{
    width: 80px;
    height: 60px;
    font-size: 18px;
    border: 1px solid;
    border-color: blue;
  }

@media screen and (max-width: 768px){
  color: white;
  background-color: ${ (props) => props.theme.primary};
  }
`;

export const RedButton = styled(Button)`
background-color: ${ (props) => props.theme.primary};
color: white;
height: 50px;
width: auto;
font-size: 16px;
margin-top: 5%;

:focus{
  border: 1px solid;
  border-color: white;
  height: 60px;
}

:disabled{
  background-color: grey;
  
}
  
`;
