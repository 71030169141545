import React from 'react';

const InputContext = React.createContext({input: {
    file: null,
    text: null
}, setInput: () => {} });

export const InputProvider = InputContext.Provider;
export const InputConsumer = InputContext.Consumer;

export default InputContext;