import styled from 'styled-components';
import { Save } from '@styled-icons/boxicons-regular/Save';

export const SaveIcon = styled(Save)`
    color: white;
    width: 30px;

    @media screen and (max-width: 768px){
        width: 25px;
    }
`

export const SaveButton = styled.button`
    border-radius: 30px;
    border: none;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 100;
    bottom: 18%;
    right: 10%;
    background-color: #F1412B;
    box-shadow: 2px 2px 5px 5px rgba(0,0,0,0.2);

    :focus{
        background-color: #1679C5;
    }

    @media screen and (max-width: 768px){
        width: 40px;
        height: 40px;
        bottom: 10%;
        right: 10%;
    }
`

export const Container = styled.div`
    display: flex;
    max-width: 100%;
    max-height: 80%;
    margin-right: 2%;
    margin-left: 27%;
    margin-top: 0%;
    position: relative;

    @media screen and (max-width: 55em){
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5%;
    }
`


