import React from 'react';
import Navbar from '../../components/navbar';
import Apresentacao from './Sections/Apresentacao';
import Objetivos from './Sections/Objetivos';


const Home = () => {

  return <div>
      <Navbar/>
      <Apresentacao/>
      <Objetivos/>
  </div>
}
export default Home;
