export default {
    home: "/",
    about: "/sobre-nos",
    contact: "/contato",
    partners: "/parcerias",
    prices: "/planos",
    register: "/cadastro",
    login: "/login",
    results: "/resultados"
  };
  