import Echo from "laravel-echo"
import pusher from "pusher-js"

window.Pusher = pusher

class SocketHandler {
    // Constructor
    constructor() {
        // Set instance attributes
        console.log('Building handler instance...')
        this._echo = null
        this._listenersOnPublic = []
        this._listenersOnPrivate = []
    }
    // Start using Laravel Echo
    start(options) {
        console.log('Starting Echo...')
        // Avoid creating more than one instance
        if ( this._echo != null )
            this.clear()
        // Instantiate echo with custom options
        this._echo = new Echo( Object.assign({
            broadcaster: 'pusher',
            key: 'ba7be493-22eb-4eef-ba4e-939f998e1734',
            wsHost: "localhost",
            wsPort: 6001,
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
        }, options));
    }
    // Disconnect from everything and free data
    clear() {
        // Leave all public channels
        for ( let channelName of this._listenersOnPublic )
            this._echo.leave( channelName )
        // Leave all private channels
        for ( let channelName of this._listenersOnPrivate )
            this._echo.leave( channelName )
        // Reset data
        this._echo = null
        this._listenersOnPublic = []
        this._listenersOnPrivate = []
    }
    // Retrieve public channel
    publicChannel( channelName ) {
        // Remember channel to leave it when calling clear()
        if ( this._listenersOnPublic.indexOf( channelName ) === -1 )
            this._listenersOnPublic.push( channelName )

        return this._echo.channel( channelName )
    }
    // Retrieve private channel
    privateChannel( channelName ) {
        // Remember channel to leave it when calling clear()
        if ( this._listenersOnPrivate.indexOf( channelName ) === -1 )
            this._listenersOnPrivate.push( channelName )

        return this._echo.private( channelName );
    }
}

var sockethandler = new SocketHandler()

// Attach websocket handler to window
window.sockethandler = sockethandler

export default sockethandler;
