import styled from 'styled-components';

export const GridDiv = styled.div`
  margin: auto;
  width: 390px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  text-align: left;

  @media only screen and (min-width: 757px) {
    width: 720px;
  }

  @media only screen and (min-width: 1275px) {
    display: flex;
    width: 1240px;
  }
`

export const MyCard = styled.div`
    padding: 1.5%;
    margin: auto;
    margin-top: 10px;
    border-radius: 5px;
    -moz-box-shadow: 3px;
    -webkit-box-shadow: 3px;
    box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.5);
`

export const QuadCard = styled(MyCard)`
  width: 271px;
  height: 271px;
`
export const FullHorizontalCard = styled(MyCard)`
  height: 241px;
  width: 100%;
`

export const VerticalCard = styled(MyCard)`
  width: 271px;
  height: 553px;
`

export const HorizontalCard = styled(MyCard)`
  width: 271px;
  height: 271px;
  padding: 6%;

  @media only screen and (min-width: 757px) {
    width: 570px;
    height: 271px;
    padding: 2.5%;
    display: flex;
  }
`

export const HorizontalCard2 = styled(MyCard)`
  width: 271px;
  height: 552px;
  
  @media only screen and (min-width: 757px) {
    width: 570px;
    height: 271px;
    display: flex;
  }
`

export const VerticalLine = styled.div`
  padding-bottom: 20px;

  @media only screen and (min-width: 757px) {
    padding-bottom: 0px;
    border-left: 1px solid #5f5f5f;
    height: 211px;
  }
`

export const CardTitle = styled.text`
    float: left;
    font-family: 'Lato', sans-serif;
    font-size: 1em;
    font-weight: 500;
    color: #5f5f5f;
    max-width: 200px;
    margin-right: 5%;
`

export const Button = styled.button`
    background: red;
    color: white;
    font-size: 0.9em;
    width: 100%;
    padding: 2px 30px 2px 30px;
    border: 1px solid red;
    border-radius: 3px;
`

export const DashboardGrid = styled.div`
    display: grid;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: left;
    margin: auto;
    grid-template-columns: auto;

    @media only screen and (min-width: 500px) {
      grid-template-columns: auto;
    }

    @media only screen and (min-width: 700px) {
      grid-template-columns: auto auto;
    }

    @media only screen and (min-width: 1000px) {
      grid-template-columns: auto auto auto;
    }

    @media only screen and (min-width: 1500px) {
      grid-template-columns: auto auto auto auto;
    }

    @media only screen and (min-width: 2000px) {
      grid-template-columns: auto auto auto auto;
    }

`