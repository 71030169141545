import styled from 'styled-components';
import { Cancel } from '@styled-icons/material-outlined/Cancel';

export const ProgressBar = styled.progress`
   height: 15px;
   border-radius: 20px;
   background-color: white;
   width: 120px;

   ::-webkit-progress-bar{
        height: 15px;
        border-radius: 20px;
        background-color: #eee;
    }

    ::-webkit-progress-value{
        height: 15px;
        border-radius: 20px;
        background-color: #F1412B;
    }

    ::-moz-progress-bar{
        height: 15px;
        border-radius: 20px;
        background-color: #F1412B;
    }
`
export const Container = styled.div`
  padding: 40px 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  min-height: 60px;
  z-index: 1;
  border-bottom: 1px solid #ECECEC;
  box-sizing: border-box;
`
export const CancelIcon = styled(Cancel)`
    color: #808080;
    width: 25px;
`