import axios from "axios";

//const api = axios.create({ baseURL: "http://localhost:8000/api" });
const api = axios.create({ baseURL: "https://api.contextoantiplagio.com.br/api" });


//Função que retorna o token do localStorage
const getToken = () => {
    return localStorage.getItem("token");
}


//Rotas de autenticação
export const signUp = (data) => api.post('/authorization/signup', data).then((res) => res.data)
export const login = (data) => api.post('/authorization/login', data);
export const logout = () => api.get('/authorization/logout', {
    headers: {'Authorization': 'Bearer ' + getToken()
}
  })
export const identify = () => api.get('/authorization/identify').then((res) => res.data);


//Rotas de comparação
export const sendData = (data) => api.post('/algorithm/file', data, { 
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}).then((res) => res.data); //Arquivo + texto

export const sendStrings = (strings) => api.post('/algorithm/text' , strings
).then((res) => res.data); //texto + texto

//Rota de contato
export const sendContact = (data) => api.post('/contact', data).then((res) => res.data)

export default api;