import React, {useState, useContext, useEffect} from 'react';
import {Editor, EditorState, RichUtils} from 'draft-js';
import InputContext from '../../InputContext';
import { SaveIcon, SaveButton, Container} from './styles';
import 'draft-js/dist/Draft.css';

function TextEditor(){

    const { setInput, input } = useContext(InputContext);

    //Configuração de estado do editor
    const [ editorState, setEditorState ] = useState(
        () => EditorState.createEmpty(),
    );

    //Configuração do handleKeyCommand
    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const onSave =  () => {
        const current = editorState.getCurrentContent();
        const text = current.getPlainText('\u0001')
        if( current.hasText()){
            console.log(text)
            setInput({
                text: text,
                file: input.file
            })
        }
    }

    useEffect(() => {
        if(input.text === null && input.file === null){
            setEditorState(() => EditorState.createEmpty());
        }
    }, [input]);

    return(
        <Container>
            <Editor
             editorState={editorState}
             handleKeyCommand={handleKeyCommand} 
             onChange={setEditorState}
             placeholder="Insira seu texto aqui"
             ariaLabel="Input de texto para comparação"
             />
             <SaveButton aria-label="Salvar texto" onClick={onSave}><SaveIcon aria-hidden="true" focusable="false"/></SaveButton>
        </Container>
    );
}

export default TextEditor;