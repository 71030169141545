import React from 'react';
import { Back, CardContainer, Card, Title, Content, SectionTitle, Text } from './styles';
import { RedButton } from '../../../../components/buttons/index';
import { Link } from "react-router-dom";

const Objetivos = () => {

    return <div>
            <Back>
            <SectionTitle>Uma solução para cada usuário</SectionTitle>
            <Text>Seja empresa ou instituição de ensino, pessoa jurídica ou física, possuímos o plano perfeito para você.</Text>
               <CardContainer>
                   <Card>
                       <Title>Contexto Antiplágio Profissional</Title>
                       <Content>Assine, amplie o número de consultas e utilize em sua carreira ou empresa.
                        <br/>
                        <br/>
                        Evolua para outro patamar de pesquisa, revisão de textos, editoração, edição, escrita, advocacia, perícia
                        e muitos outros campos
                    </Content>
                    <Link to="/planos" > 
                        <RedButton>Conheça nossos planos</RedButton>
                    </Link>
                   </Card>
                   <Card>
                       <Title>Contexto Dedo-duro</Title>
                       <Content>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque in posuere risus. Ut eleifend euismod nisl, et tempor turpis pellentesque et. Pellentesque tincidunt lobortis nisi non auctor. Mauris sodales ante sed congue commodo. Praesent nec turpis posuere, blandit nunc aliquam, bibendum erat. </Content>
                       <Link to="/dedo-duro">
                            <RedButton>Experimente nossos serviços</RedButton>
                       </Link>
                   </Card>
                </CardContainer>     
            </Back>
    </div>
}

export default Objetivos;