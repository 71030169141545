import styled from 'styled-components';

export const Layout = styled.div`
    display: flex;
    min-height: calc(100vh - 96px);
    background-color: ${ (props) => props.theme.light};
    justify-content: center;
    align-items: center;
    
    @media screen and (max-width: 769px) {
    background-color: white;
    margin-top: none;
  }
`;

export const Image = styled.img`
  width: 45%;
  object-fit: contain;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Content = styled.div`
 display: flex;
  background-color: white;
  width: 75vw;
  height: 80vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin-top: none;
    box-shadow: none;
  }
`
export const Title = styled.h1`
  font-size: 2em;
  font-weight: normal;
  margin-bottom: 5vh;
  margin-top: 5vh;
  color: #4c4c4c;
  text-align: center;
  font-family: 'Prata', serif;

  @media screen and (max-width: 768px) {
    margin-top: 0vh;
  }
`

export const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: none;
    margin-bottom: auto;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
      max-width: 100%;
  }
`

export const Text = styled.p`
    font-family: 'Lato', sans-serif;
    display: block;
    max-width: 500px;
    margin-right: auto;
    margin-bottom: 2%;
    margin-left: 5%;
    color: #232b2b;
    font-size: 1em;
    line-height: 1.4em;
    font-weight: 500;
    margin-top: 2%;


    @media screen and (max-width: 39em) {
        font-family: 'Lato', sans-serif;
        margin-bottom: 4%;
        padding-right: 12px;
        padding-left: 12px;
        font-size: 1em;
    }
`