import styled from 'styled-components';
import background from '../../../../images/background5.jpg';
import background_mobile from '../../../../images/background6.jpg';
import { RedButton } from '../../../../components/buttons';

export const Imagem = styled.div`
    background-image: -webkit-linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),url(${background});
    width: 100%;
    min-height: 90vh;
    min-height: 800px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll, fixed;
    color: #95989a;
    text-align: center;
    padding-top: 5vh;
    clear: right;

    @media screen and (max-width: 39em) {
        background-image: -webkit-linear-gradient(
            rgba(255, 255, 255, 0.7),
            rgba(255, 255, 255, 0.7)
            ),url(${background_mobile});
    }
`;

export const PageTitle = styled.h1`
    font-family: 'Lato', sans-serif;
    display: block;
    max-width: 800px;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    font-size: 2.5em;
    font-weight: 400;
    letter-spacing: 1px;
    color: #5f5f5f;

    @media screen and (max-width: 39em) {
        font-family: 'Lato', sans-serif;
        font-size: 1.5em;
        line-height: 1.5em;
        margin-bottom: 0.3em;
        margin-top: 0 em;
    }
`
export const Text = styled.p`
    font-family: 'Lato', sans-serif;
    display: block;
    max-width: 500px;
    margin-right: auto;
    margin-bottom: 2%;
    margin-left: auto;
    color: #232b2b;
    font-size: 1.8em;
    line-height: 1.4em;
    font-weight: 300;
    margin-top: 2%;


    @media screen and (max-width: 39em) {
        font-family: 'Lato', sans-serif;
        margin-bottom: 4%;
        padding-right: 12px;
        padding-left: 12px;
        font-size: 1em;
    }
`

export const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;


    @media screen and (max-width: 55em) {
        width: 90%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        align-items: left;
        margin: auto;
    }
`
export const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 90%;
    margin: auto;
`

export const Button = styled(RedButton)`
   margin-left: auto;
   margin-right: auto;
   margin-top: 5%;
   width: 250px;
`

export const SmallText = styled.p`
    font-family: 'Lato', sans-serif;
    color: #232b2b;
    text-align: center;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 0%;
    font-size: 0.9em;
    font-weight: 500;
    max-width: 90%;
`