import React, { useContext} from 'react';
import { Redirect } from 'react-router-dom';
import Navbar from '../../components/navbar';
import Input from '../../components/input';
import { signUp } from '../../services/api';
import { Layout, Image, Content, Title, Form, ColumnContainer, SubmitButton} from './styles';
import { useForm } from "react-hook-form";
import UserContext from '../../UserContext';

const Register = () =>{

  const { user, setUser } = useContext(UserContext);
  const { register, handleSubmit, reset} = useForm();

  const onSubmit = (data, e) => {
    signUp(data).then( (res) => {
      //console.log(res);
      setUser({
        user: res.user,
        token: res.token
      });
      localStorage.setItem("token", res.token);
    })
    e.target.reset();
  }
  return user.token ? (
    <Redirect
      to={"/"}
    />
  ) : (
    <>
      <Navbar/>
      <Layout>
        <Content>
          <Image
          height='75%'
          style={{marginTop: '5%', marginLeft: '2%'}}
          src={require("../../images/personal_data_.png")}
          alt="óculos e texto"
          />
          <ColumnContainer>
            <Title>Crie sua conta</Title>
            <Form onSubmit={handleSubmit(onSubmit)}>
                    <Input placeholder="Nome" name="name" autoFocus="on" ref={register({ required: true })}></Input>
                    <Input placeholder="E-mail" name="email" type="email" ref={register({ required: true })}></Input>
                    <Input placeholder="Senha" name="password" type="password" ref={register({ required: true, minLength: 8 })}></Input>
                    <Input placeholder="Confirmar senha" name="c_password" type="password" ref={register({ required: true, minLength: 8 })}></Input>
                <SubmitButton type="submit">Cadastrar</SubmitButton>
            </Form>
          </ColumnContainer>
        </Content>
      </Layout>
  </>
  )
}
export default Register;