import styled from "styled-components";

export const Container = styled.div`
  background-color: #E1270E;
  color: #ffffff;
  padding: 1em;
  height: 96px;
  display: flex;
  justify-content: center;
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 960px;

  @media (max-width: 1080px) {
    width: 768px;
  }
`;

export const Logo = styled.img`
  width: 240px;
  margin-left: -70px;
`;

export const Links = styled.div`
  width: 75%;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
    font-weight: 400;
    color: #ffffff;

    :hover {
      font-weight: 600;
    }
  }

  @media screen and (max-width: 768px) {
    transition: 0.5s ease-out;
    position: absolute;
    top: 96px;
    right: 0;
    width: 100%;
    height: max-content;
    background-color: white;
    box-shadow: 5px 5px 2.5px 0px rgba(0,0,0,0.25);
    z-index: 100;

    a {
      color: #E1270E;
    }

    display: flex;
    flex-direction: column;
    align-items: center;

    transform: translateX(${(props) => (props.show ? "0" : "100%")});

    & > * {
      margin: 0.4em;
    }
  }
`;

export const Burguer = styled.div`
  cursor: pointer;

  @media screen and (min-width: 769px) {
    display: none;
  }

  div {
    width: 24px;
    height: 3px;
    margin: 5px;
    background-color: white;
  }
`;




