import React, {useContext} from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import InputContext from '../../InputContext';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { ProgressBar, Container, CancelIcon } from './styles'

function FileUploader(){
  const { input ,setInput } = useContext(InputContext)

    // specify upload params and url for your files
  const getUploadParams = ({ meta }) => { 
    return { url: 'https://httpbin.org/post' } 
  }
  
  // called every time a file's `status` changes
  const handleChangeStatus = ({ file, remove }, status) => {
     if (status === 'done'){
       console.log(file)
       setInput({
         file: file,
         text: input.text
        })
      }
      if(status === 'rejected_file_type'){
        toast.info("Arquivo não suportado! Insira um arquivo .pdf, .doc, .docx, .odt ou .txt", {position: toast.POSITION.TOP_CENTER})
      }
      console.log(status)
  }

  const CustomPreview = ({ meta, fileWithMeta}) => {
    const { name, percent, status} = meta
    const { remove } = fileWithMeta
    if(input.file === null && input.text === null && status === 'done'){
      remove();
    }
      return (
        <Container>
            <span style={{ alignSelf: 'flex-start',fontFamily: 'Helvetica', marginLeft: '0%', fontSize: '14px'}}>
              {name}
            </span>
            <ProgressBar value={percent} max="100"></ProgressBar>
            <span onClick={remove}><CancelIcon></CancelIcon></span>
        </Container>
      )
  }

    return(
        <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        PreviewComponent={CustomPreview}
        accept="application/pdf, text/plain, .docx, .doc, .odt"
        inputContent="Insira aqui seu arquivo de comparação (.odt, .pdf, .doc, .docx ou .txt)"
        multiple={false}
        maxFiles={1}
        styles={{ dropzone: {backgroundColor: '#fffef7', margin: 'auto', width: "300px", overflow: "auto", maxWidth: "100%"}, inputLabel: {fontFamily: "'Lato', sans-serif", color: "#808080", fontSize: "1em", fontWeight: 400} }}
        />
      );
};

export default FileUploader;