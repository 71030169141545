import styled from 'styled-components';

export const Back = styled.div`
    width: 100%;
    min-height: 90vh;
    min-height: 800px;
    text-align: center;
    padding-top: 10vh;
    clear: right;
    background-color: #1679C5;


    @media screen and (max-width: 55em) {
        display: flex;
        flex-direction: column;

    }
`

export const SectionTitle = styled.h1`
    font-family: 'Lato', sans-serif;
    display: block;
    max-width: 800px;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    font-size: 2.8em;
    font-weight: 400;
    letter-spacing: 1px;
    color: white;

    @media screen and (max-width: 39em) {
        font-family: 'Lato', sans-serif;
        font-size: 1.5em;
        line-height: 1.5em;
        margin-bottom: 0.3em;
    }
`

export const CardContainer = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 5%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;

    @media screen and (max-width: 55em) {
        width: 90%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        align-items: center;
    }
`

export const Content = styled.p`
    font-family: 'Lato', sans-serif;
    color: #373d44;

    font-size: 1.15em;
    line-height: 1.4em;
    font-weight: 300;
     
    @media (max-width: 75em) {
        font-size: 1.05em;
    }

    @media screen and (max-width: 39em) {
        font-size: 0.95em;
    }
`
export const Title = styled.p`
    color: #F1412B;
    font-family: 'Lato', sans-serif;
    font-size: 1.9em;
    line-height: 1.1em;
    margin-bottom: 20px;
    text-align: center;
    @media screen and (max-width: 55em) {
       font-size: 1.5em;
       line-height: 1.1em;
       margin-bottom: 16px;
    }

`

export const Text = styled.p`
    font-family: 'Lato', sans-serif;
    display: block;
    max-width: 700px;
    margin-right: auto;
    margin-bottom: 0.2%;
    margin-left: auto;
    color: white;
    font-size: 1.8em;
    line-height: 1.4em;
    font-weight: 350;
    margin-top: 2%;


    @media screen and (max-width: 39em) {
        font-family: 'Lato', sans-serif;
        margin-bottom: 4%;
        padding-right: 12px;
        padding-left: 12px;
        font-size: 1em;
    }
`
export const Card = styled.div`
    margin: 0 10%;
    padding: 2% 3%;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 5px 1px rgba(32, 32, 32, 0.2);
    text-align: center;
    max-width: 25em;
    
    @media (max-width: 75em) {
        margin: 3%;
        padding-right: 3%;
        padding-left: 3%;  
        max-width: 30em;
    }
   
    @media screen and (max-width: 39em) {
        
        display: block;
        margin-right: auto;
        margin-bottom: 3%;
        margin-left: auto;
        padding: 4% 5% 3%;
    }
`


