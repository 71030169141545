import React from 'react'
import 'semantic-ui-css/semantic.min.css';
import { Icon, Segment, Grid, GridColumn, GridRow } from 'semantic-ui-react'
import Navbar from '../../components/navbar'
import ChartComponent from '../../components/chartComponent'
import { Bar, Bubble, Doughnut, HorizontalBar, Line, Pie, Polar, Radar, Scatter } from 'react-chartjs-2'
import { GridDiv, Button, VerticalCard, HorizontalCard, HorizontalCard2, QuadCard, FullHorizontalCard, VerticalLine } from './styles';
// import { SideBar } from 'styled-icons/remix-fill'


const DashboardComponent = (props) => {
  return (
    <div>
      <Navbar />

      <GridDiv>
        <div style={{
          margin: 'auto',
          width: '97%',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingBottom: '20px'
        }}>
          <Grid centered>
            <Grid.Row>
              <Grid stackable>
                <Grid.Column width={8}>
                  <QuadCard>
                    <div style={{ padding: '4%', maxWidth: '50%', textAlign: 'left' }}>
                      <h3>Resultado consolidado</h3>
                      <h1 style={{ fontSize: '5em', color: 'red' }}>XX%</h1>
                      <p>de chance de ser cópia do texto integral</p>
                    </div>
                  </QuadCard>
                  <QuadCard>
                    <div style={{ padding: '4%', maxWidth: '50%', textAlign: 'left' }}>
                      <h3>Na íntegra</h3>
                      <h1 style={{ fontSize: '5em', color: 'red' }}>XX%</h1>
                      <p>de igualdade, na íntegra, com o texto encontrado</p>
                    </div>
                  </QuadCard>
                </Grid.Column>
                <Grid.Column width={8}>
                  <VerticalCard>
                    <div style={{ textAlign: 'left', padding: '5%' }}>
                      <h3>Termos mais utilizados</h3>

                      <ul style={{ paddingTop: '30px' }}>
                        <li>{"xxxx"} palavras coincidentes do total de {"xxxx"} palavras</li>
                        <li>{"xxxx"} frases coincidentes do total de {"xxxx"} frases</li>
                        <li>média de {"xxxx"} palavras coincidentes por frase</li>
                        <li>{"xxxx"} parágrafos coincidentes do total de {"xxxx"} parágrafos</li>
                      </ul>

                      <HorizontalBar
                        data={{
                          labels: ['palavra 1', 'palavra 2', 'palavra 3', 'palavra 4', 'palavra 5'],
                          datasets: [
                            {
                              data: [10, 13, 7, 1, 5],
                              pointBorderColor: 'rgba(75,192,192,1)',
                              hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                              backgroundColor: [
                                'rgba(242, 38, 19, 1)',
                                'rgba(242, 38, 19, 1)',
                                'rgba(242, 38, 19, 1)',
                                'rgba(242, 38, 19, 1)',
                                'rgba(242, 38, 19, 1)'
                              ],
                              borderColor: [
                                'rgba(242, 38, 19, 1)',
                                'rgba(242, 38, 19, 1)',
                                'rgba(242, 38, 19, 1)',
                                'rgba(242, 38, 19, 1)',
                                'rgba(242, 38, 19, 1)'
                              ],
                              fill: false,
                              pointBackgroundColor: '#fff',
                              pointBorderWidth: 1,
                              pointHoverRadius: 5,
                              pointHoverBorderWidth: 2,
                              pointRadius: 1,
                              pointHitRadius: 10,
                            }
                          ]
                        }}
                        width={260}
                        height={211}
                        options={{ maintainAspectRatio: true, legend: { display: false } }}
                      />
                    </div>
                  </VerticalCard>
                </Grid.Column>
              </Grid>
            </Grid.Row>
            <Grid.Row>
              <Grid stackable>
                <Grid.Column width={16}>
                  <HorizontalCard>
                    <div style={{ textAlign: 'left' }}>
                      <h3>Resultado consolidado</h3>
                      <ul>
                        <li>{"xxxx"} palavras coincidentes do total de {"xxxx"} palavras</li>
                        <li>{"xxxx"} frases coincidentes do total de {"xxxx"} frases</li>
                        <li>média de {"xxxx"} palavras coincidentes por frase</li>
                        <li>{"xxxx"} parágrafos coincidentes do total de {"xxxx"} parágrafos</li>
                        <li>média de {"xxxx"} palavras coincidentes por parágrafo</li>
                      </ul>
                    </div>
                  </HorizontalCard>
                </Grid.Column>
              </Grid>
            </Grid.Row>
          </Grid>
        </div>
        <div style={{
          margin: 'auto',
          width: '97%',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingBottom: '20px'
        }}>
          <Grid centered>
            <Grid.Row>
              <Grid stackable>
                <Grid.Column width={8}>
                  <Grid.Row>
                    <QuadCard />
                    <QuadCard />
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Grid.Row>
                    <QuadCard />
                    <QuadCard />
                  </Grid.Row>
                </Grid.Column>
              </Grid>
            </Grid.Row>
            <Grid.Row>
              <Grid stackable>
                <Grid.Column width={16}>
                  <HorizontalCard style={{ alignItems: 'center', textAlign: 'left' }}>
                    <div style={{ padding: '5%', minWidth: '50%' }}>
                      <h2>O seu texto {"muito provavelmente"} é uma cópia!</h2>
                    </div>
                    <VerticalLine />
                    <div style={{ padding: '5%', minWidth: '50%' }}>
                      <h3>Baixe os resultados!</h3>
                      <div style={{ display: 'flex' }}>
                        <Button style={{ marginRight: '10px' }}>Baixar como PDF</Button>
                        <Button>Enviar por e-mail</Button>
                      </div>
                    </div>
                  </HorizontalCard>
                </Grid.Column>
              </Grid>
            </Grid.Row>
          </Grid>
        </div>
      </GridDiv>
    </div>
  )
}


export default DashboardComponent