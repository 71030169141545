import React from 'react';
import Navbar from '../../components/navbar';

const Prices = () =>{
  return <div>
      <Navbar/>
      <div>
        <h1>esta é a planos</h1>
      </div>
  </div>
}
export default Prices;