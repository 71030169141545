import React from 'react';

const ResultContext = React.createContext({result: {
        id: '',
        status: '',
        resultado: null,
}, setResult: () => {} });

export const ResultProvider = ResultContext.Provider;
export const ResultConsumer = ResultContext.Consumer;

export default ResultContext;