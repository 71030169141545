import styled from 'styled-components';

export const Textarea = styled.textarea`
    border: 1.8px solid #c2c2c2;
    border-radius: 8px;
    outline: ${ (props) =>
        props.invalid ? "1px solid" + props.theme.danger : "1 px solid" + props.theme.variant};
    padding: 0.8em 1em;
    height: max-content;
    width: 20vw;
    margin-top: 0.1rem;
    margin-bottom: 1.5rem;
    margin-left: 20%;
    background-color: white;
    font-size: 1.2em;

    ::placeholder {
        color: #707070;
        opacity: 1;
    }

    @media screen and (max-width: 769px) {
        width: 60vw;
    }

`